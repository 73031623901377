<template>
  <div class="card card-fullheight">
    <div class="card-body px-3 pt-3 pb-0">
      <div class="row">
        <div class="col-8 px-0">
          <span class="currency">{{ currency }}</span>
          <div class="mt-4">
            <small class="title d-block pb-1">Pendente</small>
            <span class="currency-value d-block">$ {{ $util.formatNumber(value) }}</span>
          </div>
        </div>

        <div class="col-4 px-0">
          <div class="mb-4">
            <small class="title d-block pb-1">Pago</small>
            <span class="currency-value-paid d-block">$ {{ $util.formatNumber(value_paid) }}</span>
          </div>
          <div class="mb-4">
            <small class="title d-block pb-1">Em atraso</small>
            <span class="currency-value-overdue d-block">$ {{ $util.formatNumber(value_overdue) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InvoiceCardCurrency',
  props: {
    currency: {
      type: String,
      require: true
    },
    value: {
      type: Number,
      require: true
    },
    value_paid: {
      type: Number,
      require: true
    },
    value_overdue: {
      type: Number,
      require: true
    }
  },
  methods: {
  }
}
</script>

<style scoped>
  .currency {
    color: #84754E;
    font-weight: 600;
  }
  .title {
    min-height: 0;
    font-size: 10px;
    color: #A5A4A4;
  }
  .currency-value {
    font-size: 25px;
    font-weight: 600;
    color: #383838;
  }
  .currency-value-paid, .currency-value-overdue {
    font-size: 13px;
    font-weight: 600;
  }
  .currency-value-paid {
    color: #71CC74;
  }
  .currency-value-overdue {
    color: #F3614E;
  }
</style>
