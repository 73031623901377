<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <div class="row justify-content-md-center">
      <div class="col-12">
        <filter-side-bar>
          <template slot="ContentSideBar">
            <form id="processes-list-filter" v-on:submit.prevent="applyFilter()">
              <h5 class="mb-0">Filtrar por:</h5>

              <div>
                <div>
                  <multiple-select :options="customerList" :id-field="'id_customer'" :placeholder="this.$i18n.t('form.customer.label')" :fetchOptions="customerFetchOptions" :value="filters.filCliente" />
                </div>
              </div>

              <div>
                <div class="md-form mb-0">
                  <input id="internal_code" name="internal_code" ref="internal_code"
                         :title="this.$i18n.t('form.process.internal_code')" class="md-form-control" type="text"
                         :value="filters.filProcesso">
                  <label :class="{'active': filters.filProcesso}">{{ $t('form.process.internal_code') }}</label>
                </div>
              </div>

              <div>
                <div class="md-form mb-0">
                  <input id="customer_reference" name="customer_reference" ref="customer_reference"
                         :title="this.$i18n.t('form.process.customer_reference')" class="md-form-control" type="text"
                         :value="filters.filReferenciaCliente">
                  <label :class="{'active': filters.filReferenciaCliente}">{{ $t('form.process.customer_reference') }}</label>
                </div>
              </div>

              <div>
                <div class="md-form mb-0">
                  <input id="hbl" name="hbl" ref="hbl" :title="this.$i18n.t('form.process.bl_house')"
                         class="md-form-control" type="text" :value="filter.filHBL">
                  <label :class="{'active': filters.filHBL}">{{ $t('form.process.bl_house') }}</label>
                </div>
              </div>

              <h6 class="mb-0 mt-4">{{ $t('form.process.period_time_arrival') }}</h6>
              <div class="row">
                <div class="py-1 col-md-6">
                  <div class="md-form mb-0">
                    <div class="input-group-icon input-group-icon-right mb-0">
                      <div class="md-form mb-0">
                <span class="input-icon input-icon-right">
                  <i class="ti-calendar"></i>
                </span>
                        <input data-index="2" :title="this.$i18n.t('form.process.period_time_arrival')"
                               id="arrival_begin_date" ref="arrival_begin_date" readonly
                               class="form-date md-form-control" type="text"
                               :value="$util.formatDate(filters.filPeriodoEmbarqueDtInicio)">
                        <label :class="{'active': filters.filPeriodoEmbarqueDtInicio}">{{ $t('filter.Start') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="py-1 col-md-6">
                  <div class="md-form mb-0">
                    <div class="input-group-icon input-group-icon-right mb-4">
                      <div class="md-form mb-4">
                <span class="input-icon input-icon-right">
                  <i class="ti-calendar"></i>
                </span>
                        <input data-index="2" :title="this.$i18n.t('form.process.period_time_arrival')"
                               id="arrival_end_date" ref="arrival_end_date" readonly class="form-date md-form-control"
                               type="text" :value="$util.formatDate(filters.filPeriodoEmbarqueDtFim)">
                        <label :class="{'active': filters.filPeriodoEmbarqueDtFim}">{{ $t('filter.End') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <h6 class="mb-0 mt-0">{{ $t('form.process.period_time_departure') }}</h6>
              <div class="row">
                <div class="py-1 col-md-6">
                  <div class="md-form mb-0">
                    <div class="input-group-icon input-group-icon-right mb-4">
                      <div class="md-form mb-4">
                <span class="input-icon input-icon-right">
                  <i class="ti-calendar"></i>
                </span>
                        <input data-index="2" :title="this.$i18n.t('form.process.period_time_departure')"
                               id="departure_begin_date" ref="departure_begin_date" readonly
                               class="form-date md-form-control" type="text"
                               :value="$util.formatDate(filter.filPeriodoDesembarqueDtInicio)">
                        <label :class="{'active': filters.filPeriodoDesembarqueDtInicio}">{{ $t('filter.Start') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="py-1 col-md-6">
                  <div class="md-form mb-0">
                    <div class="input-group-icon input-group-icon-right mb-4">
                      <div class="md-form mb-4">
                <span class="input-icon input-icon-right">
                  <i class="ti-calendar"></i>
                </span>
                        <input data-index="2" :title="this.$i18n.t('form.process.period_time_departure')"
                               id="departure_end_date" ref="departure_end_date" readonly
                               class="form-date md-form-control" type="text"
                               :value="$util.formatDate(filter.filPeriodoDesembarqueDtFim)">
                        <label :class="{'active': filters.filPeriodoDesembarqueDtFim}">{{ $t('filter.End') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="py-1 text-center">
                <div class="md-form mb-0">
                  <div class="row">
                    <div class="col-6">
                      <button type="submit" class="btn btn-primary btn-fill btn-block mr-2 ">{{ $t('filter.apply') }}
                      </button>
                    </div>
                    <div class="col-6">
                      <button type="button" v-on:click="resetFilter" class="btn btn-cancel btn-fill btn-block mr-2 ">{{ $t('filter.reset') }}</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </template>
        </filter-side-bar>
      </div>

      <div class="col-12 mb-3">
        <div class="card no-bottom-margin">
          <div class="section-sub-menu">
            <div class="section-sub-menu">
              <ul class="nav line-tabs borderless flex-center">
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === null }"
                     v-on:click="applyStatusFilter(null)" data-toggle="tab">Todos</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 6 }"
                     v-on:click="applyStatusFilter(6)" data-toggle="tab">{{ $t('kpi.invoices.status.pay')
                    }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 1 }"
                     v-on:click="applyStatusFilter(1)" data-toggle="tab">{{ $t('kpi.invoices.status.pending') }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 2 }"
                     v-on:click="applyStatusFilter(2)" data-toggle="tab">{{ $t('kpi.invoices.status.tenD') }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 3 }"
                     v-on:click="applyStatusFilter(3)" data-toggle="tab">{{ $t('kpi.invoices.status.fiveD') }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 4 }"
                     v-on:click="applyStatusFilter(4)" data-toggle="tab">{{ $t('kpi.invoices.status.today') }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 5 }"
                     v-on:click="applyStatusFilter(5)" data-toggle="tab">{{ $t('kpi.invoices.status.overdue') }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 7 }"
                     v-on:click="applyStatusFilter(7)" data-toggle="tab">{{ $t('kpi.invoices.status.parcial') }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- Bloco com os Filtros -->
      <div class="col-12 mb-3">
        <filter-container :activeFilterList="activeFilterList"/>
      </div>

      <div class="col-12 mb-2">
        <div class="row">
          <div class="col-12 col-md-4" v-for="(item, index) in amount" v-bind:key="index">
            <invoice-card-currency :currency="item.initials + ' / ' + item.name" :value="((item.opened)?item.opened.total:0)" :value_paid="((item.paid)?item.paid.total:0)" :value_overdue="((item.overdue)?item.overdue.total:0)" />
          </div>
         </div>
      </div>

      <div class="col-12 col-sm-12">
        <div class="row">
          <div :class="processTableSize">
            <div class="card">
              <div class="card-body">
                <!-- Table -->
                <!--<div v-on:click="toggleColumn">Config</div>-->
                <div class="table-responsive ">
                  <vuetable ref="vuetable"
                            :noDataTemplate="this.$i18n.t('noting to result')"
                            :api-url="processEndpoint"
                            :fields="fieldsTable"
                            :sort-order="sortOrder"
                            :css="css.table"
                            pagination-path="pagination"
                            :per-page="20"
                            :multi-sort="multiSort"
                            :append-params="filter"
                            multi-sort-key="shift"
                            :http-options="httpOptions"
                            track-by="id_invoice"
                            @vuetable:pagination-data="onPaginationData"
                            @vuetable:loading="onLoading"
                            @vuetable:loaded="onLoaded"
                            @vuetable:load-success="onLoadSuccess">
                            >
                    <!-- Add before the event if possible -->
                    <template slot="status" slot-scope="props">
                      <div v-html="formatStatus(getStatus(props.rowData), '-')"></div>
                    </template>

                    <template slot="actions" slot-scope="props">
                      <div class="custom-actions">
                        <router-link :to="{ path: `/processdetail?${props.rowData.id_process}`}">
                          <i class="text-primary  rc-Ativo-210 font-20"></i>
                        </router-link>
                      </div>
                    </template>

                    <template slot="custom_pay_date" slot-scope="props">
                      <span v-if="showPayDate(props.rowData)" style="font-size: 0.8rem">
                        {{ $util.formatDate(props.rowData.pay_date, undefined, ' - ') }}
                      </span>
                      <span v-else>
                        -
                      </span>
                    </template>
                  </vuetable>

                </div>
                <vuetable-pagination ref="pagination"
                                     :css="css.pagination"
                                     @vuetable-pagination:change-page="onChangePage"
                ></vuetable-pagination>
                <!-- END Table -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Vuetable, { VuetablePagination } from 'vuetable-2'
import FilterSideBar from '@/components/FilterSideBar.vue'
import FilterContainer from '@/components/FilterContainer'
import ProcessDetailsView from '@/components/ProcessDetailsView.vue'
import moment from 'moment'

// MultipleSelect
import MultipleSelect from '@/components/Forms/MultipleSelect.vue'

import InvoiceCardCurrency from '@/components/InvoiceCardCurrency.vue'
import dayjs from 'dayjs'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// Services
import CustomerService from '@/services/CustomerService'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

export default {
  name: 'ProcessList',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('kpi.invoices.title') + ' - %s'
    }
  },
  data () {
    return {
      customerList: [],
      amount: [],
      isLoading: false,
      fullPage: true,
      selectedProcess: null,
      processTableSize: 'col-12',
      processDetail: false,
      processEndpoint: process.env.VUE_APP_APIENDPOINT + 'v2/invoice',
      multiSort: true,
      activeFilterList: {
        internal_code: null,
        customer_reference: null,
        customer: [],
        hbl: null,
        id_status: null,
        arrival_begin_date: null,
        arrival_end_date: null,
        departure_begin_date: null,
        departure_end_date: null
      },
      filter: {},
      sortOrder: [
        {
          field: 'status', direction: 'asc'
        },
        {
          field: 'pay_date', direction: 'asc'
        }
      ],
      httpOptions: {
        headers: {
          'Authorization': 'Bearer ' + global.instanceApp.$Cookie.get('_userAuth_')
        }
      },
      css: {
        table: {
          tableClass: 'table table-hover align-middle',
          rowCss: 'teste',
          loadingClass: 'loading',
          ascendingIcon: 'ti-angle-double-up',
          descendingIcon: 'ti-angle-double-down',
          handleIcon: 'glyphicon glyphicon-menu-hamburger'
        },
        pagination: {
          infoClass: 'pull-left',
          wrapperClass: 'vuetable-pagination text-center',
          activeClass: 'page-item active',
          disabledClass: 'disabled',
          pageClass: 'btn btn-border',
          linkClass: 'btn btn-border',
          icons: {
            first: '',
            prev: '',
            next: '',
            last: ''
          }
        }
      }
    }
  },
  components: {
    FilterSideBar,
    Vuetable,
    VuetablePagination,
    Loading,
    FilterContainer,
    MultipleSelect,
    InvoiceCardCurrency
  },
  beforeMount () {
    this.buildFilters()
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', true)
    this.$store.dispatch('setShowSearch', false)
    this.$jQuery('#arrival_begin_date, #arrival_end_date, #departure_begin_date, #departure_end_date').datepicker({
      language: 'pt-BR',
      calendarWeeks: true,
      autoclose: true,
      templates: {
        leftArrow: '<i class="ti-angle-left"></i>',
        rightArrow: '<i class="ti-angle-right"></i>'
      }
    })
  },
  methods: {
    getStatus (row) {
      if (row.status === 2) {
        // Pago
        return 6
      } else if (row.status === 4) {
        // Parcial
        return 7
      }

      let dueDate = moment(new Date(row.pay_date))
      let today = moment(new Date())

      let duration = moment.duration(dueDate.diff(today)).asHours()
      duration = parseInt(duration)

      if (duration >= (10 * 24)) {
        return 1
      } else if (duration < (10 * 24) && duration >= (5 * 24)) {
        return 2
      } else if (duration < (5 * 24) && duration > 0) {
        return 3
      } else if (duration <= 0 && duration >= -24) {
        return 4
      } else {
        if (row.tipo_operacao === 2 && !row.data_desembarque) {
          return 8
        } else if (row.tipo_operacao === 1 && !row.data_embarque) {
          return 9
        }
        return 5
      }
    },
    buildFilters () {
      if (this.$route.params.process) {
        this.filter.internal_code = this.$route.params.process
      } else if (this.filters.filProcesso) {
        this.filter.internal_code = this.filters.filProcesso
      } else {
        this.filter.internal_code = null
      }

      this.filter.customer_reference = this.filters.filReferenciaCliente
      this.filter.hbl = this.filters.filHBL
      this.filter.id_status = null
      this.filter.arrival_begin_date = this.filters.filPeriodoEmbarqueDtInicio
      this.filter.arrival_end_date = this.filters.filPeriodoEmbarqueDtFim
      this.filter.departure_begin_date = this.filters.filPeriodoDesembarqueDtInicio
      this.filter.departure_end_date = this.filters.filPeriodoDesembarqueDtFim

      this.filter.customer = (this.filters.filCliente) ? this.filters.filCliente.map(x => (x.id)) : []
    },
    applyStatusFilter (idStatus) {
      event.preventDefault()
      this.filter.id_status = idStatus
      this.$refs.vuetable.refresh()
    },
    // Loader
    applyFilter () {
      this.customerList = []
      let dayParse = 'DD/MM/YYYY'

      this.filters.filProcesso = this.$refs.internal_code.value
      this.filters.filReferenciaCliente = this.$refs.customer_reference.value
      this.filters.filHBL = this.$refs.hbl.value
      this.filters.id_status = null
      this.filters.filPeriodoEmbarqueDtInicio = dayjs(this.$refs.arrival_begin_date.value, dayParse).isValid() ? dayjs(this.$refs.arrival_begin_date.value, dayParse).toISOString() : null
      this.filters.filPeriodoEmbarqueDtFim = dayjs(this.$refs.arrival_end_date.value, dayParse).isValid() ? dayjs(this.$refs.arrival_end_date.value, dayParse).hour(23).minute(59).second(59).toISOString() : null
      this.filters.filPeriodoDesembarqueDtInicio = dayjs(this.$refs.departure_begin_date.value, dayParse).isValid() ? dayjs(this.$refs.departure_begin_date.value, dayParse).toISOString() : null
      this.filters.filPeriodoDesembarqueDtFim = dayjs(this.$refs.departure_end_date.value, dayParse).isValid() ? dayjs(this.$refs.departure_end_date.value, dayParse).hour(23).minute(59).second(59).toISOString() : null

      this.buildFilters()

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.hideQuichSidebar()
      this.$refs.vuetable.refresh()
    },
    resetFilter () {
      // event.preventDefault()
      this.hideQuichSidebar()
      // this.filter.internal_code = null
      // this.filter.customer_reference = null
      // this.filter.hbl = null
      // // this.filter.id_status = null
      // this.filter.arrival_begin_date = null
      // this.filter.arrival_end_date = null
      // this.filter.departure_begin_date = null
      // this.filter.departure_end_date = null

      this.filters.filProcesso = null
      this.filters.filReferenciaCliente = null
      this.filters.filHBL = null
      // this.filter.id_status = null
      this.filters.filPeriodoEmbarqueDtInicio = null
      this.filters.filPeriodoEmbarqueDtFim = null
      this.filters.filPeriodoDesembarqueDtInicio = null
      this.filters.filPeriodoDesembarqueDtFim = null

      this.filters.filCliente = []

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.buildFilters()

      this.$refs.vuetable.refresh()
    },
    hideQuichSidebar () {
      const sideBar = document.querySelector('#quick-sidebar')
      document.body.classList.remove('has-backdrop')
      sideBar.classList.remove('shined')
    },
    formatStatus (value, emptyValue) {
      emptyValue = emptyValue || null

      if (!value) {
        return emptyValue
      }

      let statusList = [
        {
          'id': 1,
          'label': this.$i18n.t('form.invoices.status.opened'),
          'color': '#f1ba26'
        },
        {
          'id': 2,
          'label': this.$i18n.t('form.invoices.status.morethanfive'),
          'color': '#f1ba26'
        },
        {
          'id': 3,
          'label': this.$i18n.t('form.invoices.status.morethanone'),
          'color': '#f1ba26'
        },
        {
          'id': 4,
          'label': this.$i18n.t('form.invoices.status.today'),
          'color': '#f7941d'
        },
        {
          'id': 5,
          'label': this.$i18n.t('form.invoices.status.overdue'),
          'color': '#F3614E'
        },
        {
          'id': 6,
          'label': this.$i18n.t('form.invoices.status.paid'),
          'color': '#5ec65f'
        },
        {
          'id': 7,
          'label': this.$i18n.t('form.invoices.status.parcial'),
          'color': '#f1ba26'
        },
        {
          'id': 8,
          'label': this.$i18n.t('form.invoices.status.embarcado'),
          'color': '#f1ba26'
        },
        {
          'id': 9,
          'label': this.$i18n.t('form.invoices.status.aguardando_embarque'),
          'color': '#f1ba26'
        }
      ]

      let status = statusList.find(obj => {
        if (obj.id === value) {
          return obj
        }
      })

      // return '<span style="color: ' + status.color + '">' + status.label + '</span>'
      return '<span style="color: ' + status.color + '">' + status.label + '</span>'
    },
    onPaginationData (paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    onLoading () {
      this.hideProcessDetail()
      this.isLoading = true
    },
    onLoaded () {
      this.isLoading = false
      this.$jQuery('table.table').reflowTable()
    },
    onLoadSuccess (data) {
      this.amount = data.data.amount
    },
    onRowClicked (row) {
      this.showProcessDetails(row.data)
    },
    hideProcessDetail () {
      this.$jQuery('tr.active').removeClass('active')
      this.processDetail = false
      this.processTableSize = 'col-12'
    },
    /**
     * Display modal with Process details and files if process has files (active tab "Timeline")
     *
     * @param data Process data from line selected
     */
    showProcessDetails (data) {
      let me = this
      me.processData = data
      if (this.$store.getters.userInfo) {
        var ComponentClass = Vue.extend(ProcessDetailsView)
        var processDetailsInstance = new ComponentClass({
          data: {
            user: me.user,
            idProcess: me.processData.id_process,
            idInvoice: me.processData.id_invoice,
            process: me.processData,
            showTimeline: false,
            showAttachments: false,
            showFollowUps: false,
            showAnswerables: false,
            showInvoices: true
          }
        })
        processDetailsInstance.$mount()
        let detailsProcessContent = processDetailsInstance.$el
        global.instanceApp.$alertSwal.modalAlert(null, detailsProcessContent, null, 'no-padding', { showConfirmButton: false }).then((result) => { })
      }
    },
    /**
     * Display modal with Process details files (active tab "Anexos")
     *
     * @param data Process data from line selected
     */
    getProcessFiles (data) {
      let me = this
      me.processData = data
      if (this.$store.getters.userInfo) {
        var ComponentClass = Vue.extend(ProcessDetailsView)
        var processDetailsInstance = new ComponentClass({
          data: {
            user: me.user,
            idProcess: me.processData.id_process,
            process: me.processData,
            showTimeline: false,
            showAttachments: true,
            showFollowUps: false,
            showAnswerables: false,
            showInvoices: false
          }
        })
        processDetailsInstance.$mount()
        let detailsProcessContent = processDetailsInstance.$el
        global.instanceApp.$alertSwal.modalAlert(null, detailsProcessContent, null, 'no-padding', { showConfirmButton: false }).then((result) => { })
      }
    },
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    customerFetchOptions (search) {
      let _this = this

      return new Promise((resolve, reject) => {
        let filters = {
          'searchTerm': search
        }

        CustomerService.getCustomers(filters).then(res => {
          _this.customerList = res.data.data
          resolve(_this.customerList)
        }).catch(err => {
          reject(err)
        })
      })
    },
    showPayDate (row) {
      let showPayDate = true
      // Exportação
      if (row.tipo_operacao === 1 && row.data_embarque === null) {
        showPayDate = false
      } else if (row.tipo_operacao === 2 && row.data_desembarque === null) {
        showPayDate = false
      }

      return showPayDate
    }
  },
  computed: {
    fieldsTable () {
      return [
        {
          name: 'customer_name',
          title: this.$i18n.t('form.process.customer_name'),
          sortField: 'customer_name',
          visible: ((this.user) ? this.user.multiple_customer : false),
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'internal_code',
          title: this.$i18n.t('form.invoices.internal_code'),
          sortField: 'internal_code',
          formatter: (value) => this.$util.formatEmpty(value, ' - ')
        },
        {
          name: 'customer_reference',
          title: this.$i18n.t('form.invoices.customer_reference'),
          sortField: 'customer_reference',
          formatter: (value) => this.$util.formatEmpty(value, ' - ')
        },
        {
          name: 'hbl',
          title: this.$i18n.t('form.invoices.bl_house'),
          sortField: 'hbl',
          formatter: (value) => this.$util.formatEmpty(value, ' - ')
        },
        {
          name: 'arrival_date',
          title: this.$i18n.t('form.invoices.actual_time_arrival'),
          sortField: 'arrival_date',
          formatter: (value) => this.$util.formatDate(value, undefined, ' - ')
        },
        {
          name: 'departure_date',
          title: this.$i18n.t('form.invoices.actual_time_departure'),
          sortField: 'departure_date',
          formatter: (value) => this.$util.formatDate(value, undefined, ' - ')
        },
        {
          name: 'custom_pay_date',
          title: this.$i18n.t('form.invoices.estimated_due_date'),
          sortField: 'pay_date'
        },
        {
          name: 'currency_initials',
          title: this.$i18n.t('form.invoices.currency_initials'),
          sortField: 'currency_initials',
          formatter: (value) => this.$util.formatEmpty(value, ' - ')
        },
        {
          name: 'estimated_value',
          title: this.$i18n.t('form.invoices.estimated_value'),
          sortField: 'estimated_value',
          formatter: (value) => this.$util.formatNumber(value, undefined, undefined, undefined, ' - ')
        },
        {
          name: 'status',
          title: this.$i18n.t('form.invoices.status.title'),
          dataClass: 'text-center',
          titleClass: 'text-center'
        },
        {
          name: 'actions',
          title: 'Ações',
          dataClass: 'text-center wrap-actions',
          titleClass: 'text-center'
        }
      ]
    },
    user () {
      return this.$store.getters.userInfo || null
    },
    filters () {
      if (this.$Cookie.get('_userStoredFilters_')) {
        return JSON.parse(this.$Cookie.get('_userStoredFilters_'))
      } else {
        return {
          filCliente: [],
          filProcesso: null,
          filReferenciaCliente: null,
          filHBL: null,
          filPeriodoEmbarqueDtInicio: null,
          filPeriodoEmbarqueDtFim: null,
          filPeriodoDesembarqueDtInicio: null,
          filPeriodoDesembarqueDtFim: null
        }
      }
    },
    refreshFilter () {
      return this.$store.getters.getRefreshFilter || null
    }
  },
  watch: {
    user: function () {
      // CODIGO PARA REMOÇÂO DOS MENUS
    },
    refreshFilter: function () {
      if (this.refreshFilter) {
        this.applyFilter()
        this.$store.dispatch('setRefreshFilter', false)
      }
    }
  }
}
</script>
